import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import choiceRouter from '@/core/helpers/routers';


@Options({
    name: 'search-results-item-component',
    props: {
        results: {
            type: Array
        }
    }
})

export default class SearchResultsItemComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;

    public created():void {
        this.locale$.subscribe((res: any) => {
            if (res && res.home) {
                this.locale = res.home.search;
            }
        });
    }
    public defineService(id: number): void {
        // return choiceRouter(id);
    }
}
