<style src="./search-results.component.scss" lang="scss"></style>

<template>
  <div class="search-results mt-40 mb-100">
    <div class="container-fluid v-container">
      <v-card>
        <div class="search-results-description mb-25">
        <span class="search-results-count">
          {{ resultCount }}
        </span>
          {{ locale.result }}
        </div>
        <search-results-item-component :results="results"/>
      </v-card>
    </div>
  </div>
</template>

<script src="./search-results.component.ts"></script>
