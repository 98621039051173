import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

import SearchResultsItemComponent
    from '@/pages/search-results/components/search-results-item/search-results-item.component.vue';
import {container} from 'tsyringe';
import {SearchResultsSandbox} from '@/pages/search-results/sandbox/search-results.sandbox';
import {ISearchResult} from '@/pages/search-results/types/search-results';
import {store} from '@/store';
import {locale} from '@/assets/db/db.conf.ts';

@Options({
    name: 'search-results-component',
    components: {
        SearchResultsItemComponent,
    }
})

export default class SearchResultsComponent extends Vue {
    private sandbox: SearchResultsSandbox = container.resolve(SearchResultsSandbox);
    public locale$: any = locale;
    public locale: any = null;

    get resultCount(): number {
        return store.getters._getSearchResultCount;
    }

    get results(): ISearchResult[] {
        return store.getters._getSearchResult;
    }

    public transformationString(param: any) {
        for (let key in param) {
            if (param[key] === "") {
                param[key] = null;
            }
        }
        return param;
    }

    public searchUrl() {
        if (this.$route.query.filterCheck) {
            this.sandbox.getSearchResultsDetailed(this.$route.query);
        } else if (this.$route.query.word && !this.$route.params.filterCheck) {
            this.sandbox.getSearchResults(this.$route.query);
        }
    }

    public created(): void {
        this.searchUrl();
        if (this.$route.params.filterCheck && delete this.$route.params.filterCheck) {
            this.sandbox.getSearchResultsDetailed(this.transformationString(this.$route.params));
        } else if (this.$route.params.word && !this.$route.params.filterCheck) {
            this.sandbox.getSearchResults(this.$route.params);
        }
        this.locale$.subscribe((res: any) => {
            if (res && res.home) {
                this.locale = res.searchPage;
            }
        });
    }


}
