<style src="./search-results-item.component.scss" lang="scss"></style>

<template>
  <v-divider/>
  <div class="search-results-item mt-23 mb-28" v-for="(res,index) in results" :key="index">

    <div class="search-results-item__info ">
      <img :src="res.icon" alt="" class="d-md-block d-none">
      <div class="search-results-item__wrapper">
        <div class="search-results-item__title">
          <router-link :to="{name:res.component}">
            {{ res.name }}
          </router-link>
        </div>
        <div class="search-results-item__description">
          {{ res.structure }}
        </div>
      </div>


    </div>
    <div class="search-results-item__details">
      <div
          v-if="res.payment"
          class="search-results-item__paid">
        {{ locale.paid }}
      </div>
      <div
          v-else
          class="search-results-item__paid">
        {{ locale.noPaid }}

      </div>
      <div v-if="res.auth"
           class="search-results-item__registered">
        {{ locale.registered }}

      </div>
      <div v-else
           class="search-results-item__registered">
        {{ locale.noRegistered }}

      </div>
    </div>
  </div>
</template>

<script src="./search-results-item.component.ts"></script>
